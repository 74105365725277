@import '~antd/dist/antd.css';
@import "css/buttons.css";
@import "css/colors.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-form{
  width: 400px;
  background: var(--white);
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
}

.create-form{
  width: 400px;
  background: #f3f6f8;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.create-form-large{
  width: 800px;
  background: #f3f6f8;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.create-form-full{
  width: 95%;
  background: #f3f6f8;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.side-logo {
  border-radius: 5px;
  background: #000c17;
  margin: 16px;
  padding: 8px 8px 4px 8px;
}

.site-layout .site-layout-background {
  background: var(--white);
}

.ant-select-arrow.ant-select-arrow-loading{
  color: var(--water-blue);
}

.chart-tag{
  font-size: 24px;
  padding: 12px;
  text-align: center;
  width: 100%;
}

.ant-input-number-affix-wrapper{

}